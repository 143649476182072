<template>
  <div style="height: 100%;">
    <div class="content-data-title m-b-20">总控数据可显示项目</div>
    <div v-loading="loading"></div>
    <div style="height: calc(100% - 124px);">
      <el-transfer v-model="assembleDataSelectedItemList" :data="assembleDataItemList"
        :titles="[ `待选择项目`, '已选择项目' ]"
      ></el-transfer>
    </div>
    <div class="right-btns m-t-10">
        <el-button size="mini" type="primary" icon="el-icon-circle-check" @click="onSubmitAssembleItemConfig">提交修改</el-button>
      </div>
  </div>
</template>
<script>
import userApi from "@/api/user";

export default {
  props: {
    user: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      loading: false,
      assembleDataItemList: $statics.ASSEMBLE_DATA_ITEM_LIST.map(obj => { return { key: obj.value, label: obj.label }}),
      assembleDataSelectedItemList: [],
      assembleDataSelectedItemListBk: []
    }
  },
  created () {
    console.log("user-authority-config");
    this.getDatas ();
  },
  methods: {
    getDatas () {
      const { tel } = this.user;
      if (tel) {
        if ($statics.ASSEMBLE_DATA_CONFIG_REGX.test(tel)) {
          let assembleDataConfig = tel.match($statics.ASSEMBLE_DATA_CONFIG_REGX)[1];
          const assembleDataSelectedItemList = assembleDataConfig.split(",");
          this.assembleDataSelectedItemList = assembleDataSelectedItemList;
          this.assembleDataSelectedItemListBk = [].concat(assembleDataSelectedItemList);
        }
      }
    },
    onSubmitAssembleItemConfig () {
      let configs = this.assembleDataSelectedItemList.join();
      configs = `assemble-data-config:${configs}:assemble-data-config`;


        const user = Object.assign({}, this.user);
        let tel = user.tel || "";

        if ($statics.ASSEMBLE_DATA_CONFIG_REGX.test(tel)) {
          tel = tel.replace($statics.ASSEMBLE_DATA_CONFIG_REGX, configs);
        } else {
          tel += `,${configs}`;
        }
        user.tel = tel;

        userApi.edit(user);
    },
  }
}
</script>
<style scoped>
::v-deep .el-transfer {
  height: 100%;
}

::v-deep .el-transfer-panel__body {
  height: 100% !important;
}

::v-deep .el-transfer-panel__list {
  height: calc(100% - 104px) !important;
}
</style>