<template>
    <div class="big-data-container" v-loading="loading">
    <div class="content">
      <div class="content-btn-box" v-if="isDetail && isBase">
        <el-button size="mini" icon="el-icon-edit" type="primary":disabled="utils.userReadOnly(user)" @click.stop="onEdit">修改帐号信息</el-button>
        <el-button size="mini" icon="el-icon-delete" type="danger":disabled="utils.userReadOnly(user)" v-if="canDel" @click="onDelUser">删除帐号</el-button>
      </div>
      <div class="content-input-box content-input-box__100">
        <div class="breadcrumb-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item to="/user">账号管理</el-breadcrumb-item>
            <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div style="height: 100%;">
          <el-tabs tab-position="left" v-model="tabName" @tab-click="onTabClick">
            <el-tab-pane name="user-base" label="基本信息" style="height: 100%">
              <div class="m-l-20 m-t-10" style="height: 100%">
                <user-base v-if="tabName === 'user-base'" :data="dataObj" :is-detail="isDetail" :is-add="false" :is-base="isBase" @set-del-flag="onSetDelFlag" @close="onClose" />
              </div>
            </el-tab-pane>
            <el-tab-pane name="site-config" label="站点配置" style="height: 100%">
              <div class="m-l-20 m-t-10" style="height: 100%">
                <site-config v-if="tabName === 'site-config'" :select-user="dataObj" />
              </div>
            </el-tab-pane>
            <!-- 只有当  -->
            <el-tab-pane v-if="chcekAccessEnable()" name="authority-config" label="权限配置" style="height: 100%">
              <div class="m-l-20 m-t-10" style="height: 100%">
                <authority-config v-if="tabName === 'authority-config'" :user="dataObj" />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import userApi from "@/api/user";

import UserBase from "./user-base";
import SiteConfig from "./site-config";
import AuthorityConfig from "./authority-config";

export default {
  computed: {
    ...mapGetters(['user'])
  },
  components: {
    UserBase,
    SiteConfig,
    AuthorityConfig
  },
  data () {
    return {
      utils: $utils,
      title: "",
      isDetail: true,
      isBase: true,
      canDel: true,
      tabName: "user-base",
      dataObj: {
        username: "",
        tenantId: "",
        businessId: "",
        password: "",
        password2: "",
        mobile: "",
        staffCode: "",
        canEditCanConfig: 0,
        status: 0,
        roleId: 0,
        self: 0,
        delFlg: 0
      }
    }
  },
  async created () {
    console.log("user-detail-edit");
    this.userId = this.$route.params.userId;

    await this.getDatas();
  },
  methods: {
    async getDatas () {
      const res = await userApi.detail(this.userId);

      if (!res) {
        this.$message({
          showClose: true,
          type: "error",
          message: `找不到帐号（帐号ID：${this.userId}），请确认帐号没有错误`
        });
        this.$router.push({
          path: "/user"
        });
        return;
      }

      const route = Object.assign({}, this.$route, { title: res.username });
      this.$store.dispatch('tagsView/updateVisitedView', route);

      this.title = res.username;

      if (this.title === this.user.username) {
        this.canDel = false;
      }

      console.log(res);
      this.dataObj = res;
    },
    onEdit () {
      this.isDetail = false;
      this.title = "修改帐号信息";
    },
    onClose (user) {
      this.dataObj = user;
      this.isDetail = true;
      this.title = this.dataObj.username;

      this.$forceUpdate();
    },
    onSetDelFlag (delFlag) {
      this.canDel = !delFlag;
    },
    onTabClick () {
      this.isDetail = true;
      this.isBase = this.tabName === "user-base";
    },
    onDelUser () {
      $utils.deleteConfirm(this, "帐号", this.title);
    },
    async onDel () {
      try {
        await userApi.del(this.user.id);
        this.$message.success("帐号删除成功");
        setTimeout(() => {
          this.$router.push({
            path: `/user`
          })
        }, 500);
      } catch (err) {
        console.log(err);
        this.$message.error("帐号删除失败");
      } finally {
      }
    },
    chcekAccessEnable () {

      if (this.utils.userReadOnly(this.user)) {
        return false;
      }

      if (this.user.tenantId === 0) {
        return true;
      }

      // 自己公司上级公司（不限等级）的帐号
      const loginUserBizId = (this.user.businessId).toString();
      const staffCode = this.dataObj.staffCode.split(",");

      if (staffCode.indexOf(loginUserBizId) < 0) {
        return false;
      }

      return (staffCode[staffCode.length - 2] !== loginUserBizId);
    }
  }
}
</script>