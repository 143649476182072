var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { height: "100%" },
    },
    [
      _c(
        "div",
        { staticStyle: { height: "calc(100% - 82px)" } },
        [
          _c("el-transfer", {
            attrs: {
              "filter-placeholder": "请输入站点名称",
              filterable: "",
              data: _vm.data,
              titles: [`待分配站点`, "已分配站点"],
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "m-t-10 right-btns" },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-circle-check",
                disabled: _vm.utils.userReadOnly(_vm.user),
                type: "primary",
                size: "mini",
              },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }