var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { height: "100%" } }, [
    _c("div", { staticClass: "content-data-title m-b-20" }, [
      _vm._v("总控数据可显示项目"),
    ]),
    _c("div", {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    }),
    _c(
      "div",
      { staticStyle: { height: "calc(100% - 124px)" } },
      [
        _c("el-transfer", {
          attrs: {
            data: _vm.assembleDataItemList,
            titles: [`待选择项目`, "已选择项目"],
          },
          model: {
            value: _vm.assembleDataSelectedItemList,
            callback: function ($$v) {
              _vm.assembleDataSelectedItemList = $$v
            },
            expression: "assembleDataSelectedItemList",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right-btns m-t-10" },
      [
        _c(
          "el-button",
          {
            attrs: {
              size: "mini",
              type: "primary",
              icon: "el-icon-circle-check",
            },
            on: { click: _vm.onSubmitAssembleItemConfig },
          },
          [_vm._v("提交修改")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }