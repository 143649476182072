import request from '@/utils/request';

// 新增
export function add(data) {
  const rand = Date.now();
  return request.put(`User-Site-Relation/add?${rand}`, data);
}
// 列表
export function list(params) {
  const rand = Date.now();
  return request.get(`User-Site-Relation/List?${rand}`, { params });
}
// 修改
export function edit(data) {
  const rand = Date.now();
  return request.post(`User-Site-Relation/update?${rand}`, data);
}

// 删除
export function del (idList) {
  const rand = Date.now();
  return request.get(`User-Site-Relation/delete/${idList}?${rand}`);
}

export default {
    add,
    list,
    edit,
    del
}