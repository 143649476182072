<template>
  <div v-loading="loading" style="height: 100%;">
    <div style="height: calc(100% - 82px);">
      <el-transfer filter-placeholder="请输入站点名称" filterable v-model="value" :data="data"
      :titles="[ `待分配站点`, '已分配站点' ]"
    ></el-transfer>
    </div>
    <div class="m-t-10 right-btns">
      <el-button icon="el-icon-circle-check":disabled="utils.userReadOnly(user)" type="primary" size="mini" @click="onSubmit">提交</el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import siteApi from "@/api/site";
import userSiteRelationApi from "@/api/user-site-relation";

export default {
  computed: {
    ...mapGetters(['user'])
  },
  props: {
    selectUser: {
      type: Object,
      default: {}
    },
  },
  data () {
    return {
      utils: $utils,
      loading: false,
      selectedCompany: "",
      value: [],
      data: [],
      protoVal: [],
      siteMap: {},
    }
  },
  created () {
    this.selectedCompany = this.selectUser.businessName;
    console.log("user-config-site");

    this.getDatas();
  },
  methods: {
    async getDatas () {
      try {
        this.loading = true;

        const params = {
          page: 0,
          size: 99999999
        };

        if (this.user.tenantId !== 0) {
          params.staffCode = `,${this.selectUser.businessId},`;
        }

        const reses = await Promise.all([
          siteApi.list(params),
          siteApi.userSites(this.selectUser.id)
        ]);

        const data = reses[0].data.map(obj => {
          return {
            label: obj.siteName,
            key: obj.id
          }
        });

        const valueMap = {};
        const value = reses[1].map(obj => {
          valueMap[obj.id] = obj;
          return obj.id;
        });

        this.siteMap = valueMap;
        this.value = value;
        this.protoVal = [].concat(value);
        this.data = data;
      } catch (e) {
        this.value = [];
        this.data = [];
      } finally {
        this.loading = false
      }
    },
    onClose () {
      this.$emit("close");
    },
    onCancel () {
      $utils.cancelConfirm(this, "配置站点", () => {
        this.form = Object.assign({}, this.backForm);
        this.onClose();
      });
    },
    async onSubmit () {
      try {
        // 1. 判断有哪些新增
        const requests = [], params = [];
        for (const val of this.value) {
          if (this.protoVal.indexOf(val) < 0) {
            const param = {
              userId: this.selectUser.id,
              site: val
            };
            params.push(param);
            requests.push(userSiteRelationApi.add(param));
          }
        }
        // 2. 判断有哪些删除
        for (const val of this.protoVal) {
          if (this.value.indexOf(val) < 0) {
            const site = this.siteMap[val];
            requests.push(userSiteRelationApi.del(site.userSiteId));
          }
        }

        if (requests.length > 0) {
          const reses = await Promise.all(requests);
          for (let i = 0, len = params.length; i < len; i++) {
            const res = reses[i];
            const param = params[i];
            let site = this.siteMap[param.site];
            if (!site) {
              site = this.siteMap[param.site] = param;
            }
            site.userSiteId = res;
          }

          this.protoVal = [].concat(this.value);

          this.$message.success("站点配置修改成功");
        } else {
          this.$message.warning("您当前的选择没有变化");
        }

        this.onClose();
      } catch (err) {
        console.log(err);
        this.$message.error("站点配置修改失败");
      } finally {
      }
    }
  }
}
</script>
<style scoped>
::v-deep .el-transfer {
  height: 100%;
}

::v-deep .el-transfer-panel__body {
  height: 100% !important;
}

::v-deep .el-transfer-panel__list {
  height: calc(100% - 104px) !important;
}
</style>